<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>新建特殊页信息</a-breadcrumb-item>
            </a-breadcrumb>
            <div :style="{ padding: '24px', background: '#fff', minHeight: '160px' }" style="margin-bottom: 10px">
<!--                {{iteminfo}}-->
<!--                {{imglist220}}-->
<!--                {{imglist800}}-->
                <h1 style="font-size: larger">基本信息</h1>
                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
                    <a-form-item
                            help="建议尺寸：800*800像素，最多上传5张"
                            label="特殊页信息封面图片"

                    >
                        <!-- 点击上传拍照/上传图片 -->


                        <a-upload
                                :action="server_url+'api/admin/tools/saveFileCropper?uid='+uid+'&token='+token+'&save_path=/images&size=1044:440'"
                                :file-list="iteminfo.fileList"
                                @change="handleChange"
                                @preview="handlePreview"
                                list-type="picture-card"
                        >
                            <div v-if="iteminfo.fileList.length < 1">
                                <a-icon type="plus"/>
                                <div class="ant-upload-text">
                                    点击上传
                                </div>
                            </div>
                        </a-upload>

                        <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
                            <img :src="previewImage" alt="" style="width: 100%"/>
                        </a-modal>
                        <!--                        <a-input id="image_groups" placeholder="请输入特殊页信息名字"/>-->
                    </a-form-item>


                    <a-form-item
                            help="请输入特殊页信息名字"
                            label=" 特殊页信息标题:"

                    >
                        <a-input id="title" placeholder="请输入特殊页信息名字" v-model="iteminfo.name"/>
                    </a-form-item>

                    <a-form-item
                            help=""
                            label="特殊页信息分类:"

                    >
                        <a-cascader :options="Category"
                                    @change="onChangecascader"
                                    allowClear
                                    change-on-select
                                    placeholder="请正确选择特殊页信息分类"
                                    style="width: 70%;"
                                    :fieldNames="fieldNames"
                        />
                    </a-form-item>
                </a-form>
            </div>
            <div :style="{ padding: '24px', background: '#fff',}" style="margin-bottom: 10px">
                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
                    <h1 style="font-size: larger">特殊页信息简介</h1>
<!--                    <quill-editor-->
<!--                            v-model="short_content"-->
<!--                            ref="myQuillEditor"-->
<!--                            :options="editorOption"-->
<!--                            @change="onEditorChange1($event)">-->
<!--                    >-->
<!--                    </quill-editor>-->
                    <a-textarea
                            v-model="short_content"
                            placeholder="请输入特殊页信息简介.."
                            :auto-size="{ minRows: 3, maxRows: 15 }"
                            @change="onChange"
                    />
                </a-form>
                <h1 style="font-size: larger">特殊页信息详情</h1>
                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20}">
                    <div class="edit_container">
                        <a-upload list-type="picture" v-show="false"
                                  :action="server_url+'api/admin/tools/saveFile?uid='+uid+'&token='+token+'&save_path=/images/item'"
                                  :show-upload-list="false"
                                  :before-upload="beforeUploaditem"
                                  @change="handleChangeitem"
                        >
                            <a-button> <a-icon type="upload" /> upload </a-button>
                        </a-upload>
                        <quill-editor
                                v-model="content"
                                ref="myQuillEditor"
                                :options="editorOption"
                                @blur="onEditorBlur($event)"
                                @focus="onEditorFocus($event)"
                                @ready="onEditorReady($event)"
                                @change="onEditorChange($event)">
                        </quill-editor>
                    </div>
                </a-form>

            </div>
            <a-button @click="addarticle()" type="primary">添加特殊页信息</a-button>
<!--            <a-button @click="addsku()" type="primary" v-show="sku_array=='multiple'">添加多个特殊页信息</a-button>-->
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>

        <!--        <a-modal title="" v-model="" @ok="" width="45%">-->

        <!--        </a-modal>-->
    </a-layout>

</template>

<script>


    // import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    import moment from "moment";
    import Config from '../config'
    import {Modal} from "ant-design-vue";

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import { quillEditor } from 'vue-quill-editor'
    // import * as Util from "../common/util";
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const columns = [
        // {
        //     title: "attributes",
        //     dataIndex: "attributes",
        //     scopedSlots: {customRender: "attributes"}
        // },
        {
            title: "标题",
            dataIndex: "name",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "库存",
            dataIndex: "inventory",
            scopedSlots: {customRender: "inventory"}
        },
        {
            title: "低于该库存警告",
            dataIndex: "inventory_threshold",
            scopedSlots: {customRender: "inventory_threshold"}
        },
        // {
        //     title: "运费模板",
        //     dataIndex: "freight_model_id",
        //     scopedSlots: {customRender: "freight_model_id"}
        // },
        {
            title: "价格",
            dataIndex: "price",
            scopedSlots: {customRender: "price"}
        },
        {
            title: "成本",
            dataIndex: "cost",
            scopedSlots: {customRender: "cost"}
        },
        {
            title: "重量",
            dataIndex: "weight",
            scopedSlots: {customRender: "weight"}
        },
        {
            title: "状态",
            dataIndex: "is_sale",
            scopedSlots: {customRender: "is_sale"}
        },
        {
            title: "操作",
            dataIndex: "operation",
            scopedSlots: {customRender: "operation"}
        },
    ];
    // 工具栏配置
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
        // ["blockquote", "code-block"], //引用，代码块
        [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
        //[{ list: "ordered" }, { list: "bullet" }], //列表
        //[{ script: "sub" }, { script: "super" }], // 上下标
        [{ indent: "-1" }, { indent: "+1" }], // 缩进
        [{ direction: "rtl" }], // 文本方向
        [{ size: ["small", false, "large", "huge"] }], // 字体大小
        [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
        [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
        [{ font: [] }], //字体
        [{ align: [] }], //对齐方式
        ["clean"], //清除字体样式
        ["image"], //上传图片、上传视频
        // ["image", "video"], //上传图片、上传视频
    ];
    export default {
        components: {quillEditor},
        name: 'editor',
        data() {
            return {
                uploadImgUrl: '',
                short_content: '',
                imageUrl: '',
                content: ``,  // 富文本编辑器默认内容
                editorOption: {
                    modules: {
                        toolbar: {
                            container: this.toolbarOptions || toolbarOptions, // 自定义工具栏，与上面定义的toolbarOptions 相对应
                            handlers: {
                                'image': function(value) {
                                    if (value) {
                                        console.log('你点到我了!')
                                        // 获取到antd的upload框选择图片的弹出框事件并触发一次点击
                                        document.querySelector('.ant-upload .ant-btn').click()
                                    } else {
                                        //禁用quill自带的image时间
                                        this.quill.format('image', false)
                                    }
                                }
                            } // 事件重写
                        }
                    },
                    theme: "snow",  //主题
                    placeholder: "请输入正文",
                },
                columns,
                data: [],
                loading: false,
                add: {},
                Attributedata: [],
                Category: [],
                Attributedataarr: [],
                previewVisible: false,
                previewImage: '',
                server_url: Config.data.server_url,
                Categorydata: Storage.Categorydata,
                uid: Storage.uid,
                token: Storage.token,
                myName: Storage.myName,
                fieldNames: {
                    children: 'child',
                    label: 'title',
                    value: 'title',
                },
                imglist800: "",
                imglist220: [],
                article_category_id: "",
                article_category_selectedOptions: "",
                // sku_array:"multiple",
                iteminfo: {
                    name: "",
                    is_sale: "2",
                    price: 0,
                    cost: 0,
                    freight_model_id: 0,
                    weight: 0,
                    inventory: 0,
                    shop_id: 1,
                    inventory_threshold: 100,
                    category: "",
                    attributes: [],
                    sku_array: [],
                    fileList: [],
                    content: {
                        categorygroup:"",
                        content:"",
                    },
                }
            };
        },
        created() {
            // 获取第一页内容
            // this.doSearch(1, this.pagination.pageSize, "");
            this.getCategory();
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill;
            },
        },
        methods: {
            async getCategory(pid) {
                this.loading = true;
                this.Category = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/articleCategory/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        pid: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.loading = false;
                }

                this.loading = false;
            },
            // 准备编辑器
            onEditorReady(editor) {
                console.log('editor ready!', editor)
            },
            // 失去焦点事件
            onEditorBlur(editor){
                console.log('editor blur!', editor)
            },
            // 获得焦点事件
            onEditorFocus(editor){
                console.log('editor focus!', editor)
            },
            // 内容改变事件
            onEditorChange({ quill, html, text }){
                console.log('editor change!', quill, html, text)
                this.content = html
            },
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(1, this.pagination.pageSize);
            },
            handleCancel() {
                this.previewVisible = false;
            },
            onChangecascader(value, selectedOptions) {
                // this.skugroups=[];
                // this.Attributedataarr=[];
                // for (let i in selectedOptions) {
                //     let info = selectedOptions[i].id;
                //     console.log(info);
                //     // this.iteminfo.category = info
                //
                //     // this.add.p_lv = value
                // }
                // this.iteminfo.content.categorygroup = value
                // this.getAttribute(this.iteminfo.category);
                this.article_category_id = selectedOptions[selectedOptions.length-1].id;
                this.article_category_selectedOptions = value;
                // console.log(value);
                // console.log(selectedOptions);
                console.log(this.article_category_id );
            },
            async handlePreview(file) {

                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange(info) {
                this.imglist800="";
                // this.imglist220=[];
                // console.log(info);
                console.log(info.file.status);
                this.iteminfo.fileList = info.fileList;
                for (var i = 0; i < this.iteminfo.fileList.length; i++) {
                    if(info.file.status == "done" || info.file.status == "removed"){
                        this.imglist800 = this.iteminfo.fileList[i].response.data[0];
                        // this.imglist800.push(this.iteminfo.fileList[i].response.data[0]);
                        // this.imglist220.push(this.iteminfo.fileList[i].response.data[1]);
                    }
                    // console.log(info.fileList[i]);
                    // console.log(info.fileList[i].response.data);
                }


            },

            onChange() {
                let content = this.short_content.replace(/<[^>]*>/g,'');

                if (content.length > 200) {
                    content = content.substring(0, 200) + '...';

                }
                this.short_content = content
            },


            async doSearch(page, limit, search, rule_group_id, type) {
                this.loading = true;
                this.data = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/account/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        page: page,
                        limit: limit,
                        search: search,
                        rule_group_id: rule_group_id,
                        type: type,
                        user_type: 2,
                        t: now,
                    }
                });

                if (result.status === true) {
                    let data = [...this.data];
                    for (let i in result.data.list) {
                        let info = result.data.list[i];
                        let offset = (parseInt(page) - 1) * limit + parseInt(i);
                        // console.log(offset)

                        data[offset] = {
                            uid: info.uid,
                            name: info.name,
                            group_name: info.group_name,
                            login_ip: info.login_ip,
                            login_name: info.login_name,
                            login_time: moment(info.login_time * 1000).format("YYYY/MM/DD"),
                            // login_time: info.login_time,
                            mobile: info.mobile,
                            nike_name: info.nike_name,
                            sex: info.sex,
                            status: info.status,
                        };
                    }

                    let pagination = {...this.pagination};
                    pagination.total = result.data.total;
                    this.pagination = pagination;
                    this.data = data;
                    this.loading = false;
                }

                this.loading = false;
            },


            delarticle(id) {
                console.log("修改前数组", id);
                console.log("修改前数组", this.skugroups.attributes);
                //delete by name
                var array1 = [...this.skugroups]
                console.log("修改前", array1);
                var delid = id.attributes;
                for (var i = 0; i < array1.length; i++) {
                    var nameTemp = array1[i].attributes;
                    console.log("delid", delid);
                    console.log("nameTemp", nameTemp);
                    if (nameTemp === delid) {
                        array1.splice(i, 1);
                    }
                }

                this.skugroups = array1;

                // console.log("修改后数组", this.skugroups[0].attributes);

            },
            async addarticle() {

                //
                if (this.iteminfo.name =="") {
                    Modal.warning({
                        title: "请输入特殊页信息标题"
                    });
                    return
                }
                if (this.content =="" || this.content =="特殊页信息详情") {
                    Modal.warning({
                        title: "请输入特殊页信息正文"
                    });
                    return
                }
                if (this.article_category_id == 1) {
                    Modal.warning({
                        title: "请不要选择根作为特殊页信息分类"
                    });
                    return
                }
                if (this.article_category_id =="") {
                    Modal.warning({
                        title: "请选择特殊页信息分类"
                    });
                    return
                }
                // if (this.add.password =="") {
                //     Modal.warning({
                //         title: "请输入登录密码"
                //     });
                //     this.popinfos = true;
                //     return
                // }
                // if (this.add.password != this.add.re_password) {
                //     Modal.warning({
                //         title: "两次密码不一致"
                //     });
                //     this.popinfos = true;
                //     return
                // }
                if(this.short_content == ""){
                    let content = this.content.replace(/<[^>]*>/g,'');

                    if (content.length > 10) {
                        content = content.substring(0, 200) + '...';

                    }
                    this.short_content = content
                }


                let now = Date.now();
                let result = await this.$post("/api/admin/article/create", {
                    uid: Storage.uid,
                    token: Storage.token,
                    save_value: {
                        content:  this.content,
                        title: this.iteminfo.name,
                        short_content : this.short_content,
                        article_category_id: this.article_category_id,
                        // cover_images : JSON.stringify(this.imglist800),
                        cover_images : this.imglist800,
                        author: Storage.myName,
                        type:4,
                        model_info_json:JSON.stringify({
                            article_category_selectedOptions:this.article_category_selectedOptions,
                        }),
                        t: now,
                    },
                });

                if (result.status === true) {
                    this.data = []
                    // await this.doSearch(1, this.pagination.pageSize, "");
                    Modal.info({
                        title: "创建成功1"
                    });
                    window.vue.$router.push({path: "/specialmanger"});
                }
                // this.popadd = false;
            },

            handleChangeitem(info) {
                console.log('info', info);
                //这一步是获取quilleditor的实例
                let quill = this.$refs.myQuillEditor.quill

                // 获取光标所在位置
                let length = quill.getSelection().index
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    // Get this url from response in real world.
                    this.loading = false;
                    this.uploadImgUrl = this.server_url+info.file.response.data.src
                    console.log(length)
                    // 插入图片  res为服务器返回的图片地址
                    quill.insertEmbed(length, 'image', this.uploadImgUrl)
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                    this.$message.success('上传成功')
                    //console.log(this.url)
                    // this.$message.error('图片插入失败')
                }
            },
            beforeUploaditem(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJpgOrPng && isLt2M;
            },
        }
    };
</script>
<style>
    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
    .edit_container{min-height: 400px}
    .quill-editor{min-height: 200px}
    .ql-editor{
        height:400px;
    }
    .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: "请输入链接地址:";
    }
    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: "保存";
        padding-right: 0px;
    }
    .ql-snow .ql-tooltip[data-mode="video"]::before {
        content: "请输入视频地址:";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: "14px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
        content: "10px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
        content: "18px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
        content: "32px";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: "文本";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: "标题1";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: "标题2";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: "标题3";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: "标题4";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: "标题5";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: "标题6";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: "标准字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
        content: "衬线字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
        content: "等宽字体";
    }
</style>
